<template>
	<b-modal size="xl" ref="modalRetour" @show="init_component">
		<template slot="modal-header" slot-scope="{ close }">
			<i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
			<h2 class="mx-auto my-auto">{{ $t('monte.stock_semence.inventaire') }}</h2>
		</template>
		<template slot="default">
			<div class="row mb-3">
				<div class="col-auto">
					<label>{{ $t("monte.stock_semence.tiers_provenance") }} {{ tiers_provenance.tiers_rs }} {{ $t("monte.stock_semence.tiers_vers") }}</label>
				</div>
				<div class="col">
					<e-select
						v-model="tiers_selected"
						id="tiers"
						track-by="tiers_rs"
						label="tiers_rs"
						:placeholder="$t('tiers.search_tiers')"
						:selectedLabel="$t('global.selected_label')"
						:options="all_tiers"
						:searchable="true"
						:show-labels="false"
						:loading="loadingTiers"
					/>
				</div>
			</div>
			<div class="row">
				<div class="col-auto">
            		<label>{{ $t('monte.stallion') }}</label>
            	</div>
				<div class="col">
	                <e-select
	                    v-model="stallion_selected"
	                    id="stallion"
	                    track-by="horse_id"
	                    label="horse_nom"
	                    :placeholder="$t('monte.selectionnez_etalon')"
	                    :selectedLabel="$t('global.selected_label')"
	                    :options="stallions"
	                    :searchable="true"
	                    :allow-empty="false"
	                    :show-labels="false"
	                />
	            </div>
				<div class="col-auto">
					<button class="btn btn-secondary" @click="addStallion"><font-awesome-icon :icon="['far', 'plus']" /></button>
				</div>
			</div>
			<div v-if="retour_stock.length > 0" class="row mt-4">
				<div class="col">
				</div>
				<div class="col">
					<label>{{ $t('monte.stock_semence.dispo') }}</label>
				</div>
				<div class="col">
					<label>{{ $t('monte.stock_semence.stock') }}</label>
				</div>
				<div class="col">
                    <label>{{ $t('monte.stock_semence.date_retour') }}</label>
				</div>
				<div class="col-auto"></div>
			</div>
			<div v-for="(inventory, key) in retour_stock" class="row border-top" :key="inventory.stallion.horse_id">
				<div class="col align-self-end">
                    <div class="form-group">
                        <span>{{ inventory.stallion.horse_nom }}</span>
                    </div>
                </div>
                <div class="col mt-2">
                	{{ inventory.stock }}
                </div>
                <div class="col mt-2">
                    <div class="form-group">
                       <b-input
							type="number"
							v-model="inventory.retour"
						>
						</b-input>
                    </div>
                </div>
                <div class="col mt-2">
                    <div class="form-group">
                        <e-datepicker v-model="inventory.date"></e-datepicker>
                    </div>
                </div>
                <div class="col-auto mt-2">
                    <button class="btn btn-secondary" @click.prevent="removeStallion(key)"><font-awesome-icon :icon="['far', 'trash-alt']" /></button>
                </div>
                <div class="col-12 mb-4">
               		<textarea class="form-control" :placeholder="$t('formulaire.votre_commentaire')" v-model="inventory.commentaire"></textarea>
                </div>
			</div>
	    </template>
		<template slot="modal-footer" class="justify-content-center">
		  	<div class="text-center">
                <b-button v-if="retour_stock.length > 0" @click="checkForm" variant="primary" class="rounded-pill">
                    {{ $t('global.ajouter') }} 
                    <font-awesome-icon v-if="processing" :icon="['fal', 'sync']" :spin="processing"/>
                    <font-awesome-icon v-else :icon="['fal', 'plus-circle']" class="ml-1"/>
                </b-button>
                <b-button @click="cancelInventory" class="rounded-pill ml-1">
					{{ $t('global.annuler') }} <font-awesome-icon :icon="['fal', 'times']" class="ml-1"/> 
				</b-button>
            </div>
		</template>
	</b-modal>
</template>

<script type="text/javascript">
    import Horse from "@/mixins/Horse"
    import Tiers from "@/mixins/Tiers"
    import StockSemence from "@/mixins/StockSemence"

	export default {
		name: 'ModalRetourStock',
		mixins: [Horse, Tiers, StockSemence],
		props: ['tiers_id', 'tier_stallions'],
		data () {
			return {
				stallions: [],
				stallion_selected: {},
				retour_stock: [],
				processing: false,
				all_tiers: [],
				loadingTiers: false,
				tiers_selected: null,
				tiers_provenance: {}
			}
		},

		methods: {
			async init_component() {
				this.stallions = await this.getHorsesStallion(false)

				this.loadingTiers = true
				this.all_tiers = await this.loadTiers()
				this.loadingTiers = false

				this.tiers_provenance = this.all_tiers.find(tier => tier.tiers_id == this.tiers_id)

				this.retour_stock = []

				this.preloadStallion()
			},

			openModal() {
				this.$refs.modalRetour.show()
			},

			addStallion() {
				if(!this.stallion_selected.horse_id) {
					return false
				}

				this.retour_stock.push({
					stallion: this.stallion_selected,
					date: new Date(),
					stock: 0,
					commentaire: ""
				})

				const index = this.stallions.indexOf(this.stallion_selected)
				if (index > -1) {
					this.stallions.splice(index, 1);
				}

				this.stallion_selected = {}
			},

			async checkForm() {
				const retour_stock = this.retour_stock.map(i => {
					return {
						date: i.date,
						retour: i.retour,
						stallion: i.stallion.horse_id,
						commentaire: i.commentaire,
						from: this.tiers_id,
						to: this.tiers_selected.tiers_id
					}
				})

				this.processing = true
				await this.saveRetourStock(retour_stock)
				this.processing = false
				this.cancelInventory()
				this.$emit("done", true)
			},

			cancelInventory() {
				this.$refs.modalRetour.hide()
				this.stallions = []
				this.stallion_selected = {}
				this.retour_stock = []
			},

			preloadStallion() {
				this.tier_stallions.forEach(stallion => {
					this.retour_stock.push({
						stallion: stallion.horse,
						date: new Date(),
						stock: stallion.stock,
						retour: 0,
						commentaire: ""
					})

					const horse = this.stallions.find(s => s.horse_id == stallion.horse.horse_id)

					const index = this.stallions.indexOf(horse)
					if (index > -1) {
						this.stallions.splice(index, 1);
					}
				})
			},

			removeStallion(key) {
				this.stallions.push(this.retour_stock[key].stallion)
				this.retour_stock.splice(key, 1);
			}

		},

		components: {
            CustomTable : () => import('GroomyRoot/components/Table/CustomTable'), 
		}
	}

</script>